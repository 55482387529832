import { Box, CssVarsProvider } from '@mui/joy';
import { useEffect, useState } from 'react';
import { handleAxiosError } from '../utils/api';
import { toast } from 'react-toastify';
import { StatsType, TCombinedStats } from '../interfaces/common';
import dayjs from 'dayjs';
import { getStatsByDate } from '../services/api';
import StatsContainer from '../components/dashboard/stats-container';

const dateGlobal = dayjs('2024-12-01').format('YYYY-MM-DD');
const dateToday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
const dateLastWeek = dayjs().subtract(7, 'day').format('YYYY-MM-DD');

export default function DashboardPage() {
  const [combinedStats, setCombinedStats] = useState<TCombinedStats>({
    [StatsType.GLOBAL]: null,
    [StatsType.WEEKLY]: null,
    [StatsType.DAILY]: null
  });
  const [selectedStatsType, setSelectedStatsType] = useState<StatsType>(StatsType.GLOBAL);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getMetrics = async () => {
      try {
        setLoading(true);
        const promises = [
          getStatsByDate({ startDate: dateGlobal }),
          getStatsByDate({ startDate: dateToday }),
          getStatsByDate({ startDate: dateLastWeek })
        ];
        const res = await Promise.all(promises);
        setCombinedStats({
          [StatsType.GLOBAL]: res[0].data,
          [StatsType.DAILY]: res[1].data,
          [StatsType.WEEKLY]: res[2].data
        });
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`error fetching stats: ${errMsg}`);
        console.error('error fetching stats', error);
      } finally {
        setLoading(false);
      }
    };
    getMetrics();
  }, []);

  const handleOptionChange = (option: StatsType) => {
    setSelectedStatsType(option);
  };

  return (
    <CssVarsProvider>
      <Box sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 2 }}>
        <StatsContainer
          selectedOption={selectedStatsType}
          onOptionChange={handleOptionChange}
          loading={loading}
          combinedStats={combinedStats}
        />
      </Box>
    </CssVarsProvider>
  );
}
