import { store } from '../store';
import { resetData, resetToken, resetUser } from '../store/app';
import { unsetShakeUser } from '../helpers/shake';
import { EMAIL_REGEX } from '../constants/yup';

export const logoutUser = () => {
  store.dispatch(resetData());
  store.dispatch(resetUser());
  store.dispatch(resetToken());
  unsetShakeUser();
  window.location.href = '/';
};

export const isValidEmail = (email: string) => {
  return EMAIL_REGEX.test(email);
};
