import { StatsType, TCombinedStats } from '../../interfaces/common';
import { Box, Card, CardContent, Option, Select } from '@mui/joy';
import MetricCard from './metric-card';

interface IProps {
  selectedOption: StatsType;
  onOptionChange: (option: StatsType) => void;
  loading: boolean;
  combinedStats: TCombinedStats;
}

export default function StatsContainer({
  selectedOption,
  onOptionChange,
  loading,
  combinedStats
}: IProps) {
  const stats = combinedStats?.[selectedOption];

  return (
    <Card variant="soft" color="primary">
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
            justifyContent: 'flex-end',
            paddingRight: '0.5rem'
          }}>
          <Select
            value={selectedOption}
            onChange={(_, value) => onOptionChange(value!)}
            sx={{ width: '12rem' }}>
            {Object.values(StatsType).map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            padding: '0.5rem',
            gap: 3
          }}>
          <MetricCard metric={'ES Stats'} value={stats?.dynamoStats.totalCount} loading={loading} />
          <MetricCard
            metric={'Delivered'}
            value={stats?.sendGridStats.delivered}
            loading={loading}
          />
          <MetricCard metric={'Open'} value={stats?.sendGridStats?.opens} loading={loading} />
          <MetricCard metric={'Clicks'} value={stats?.sendGridStats?.clicks} loading={loading} />
          <MetricCard metric={'Blocks'} value={stats?.sendGridStats?.blocks} loading={loading} />
          <MetricCard
            metric={'Processed'}
            value={stats?.sendGridStats?.processed}
            loading={loading}
          />
          <MetricCard
            metric={'Spam Reports'}
            value={stats?.sendGridStats?.spam_reports}
            loading={loading}
          />
          <MetricCard
            metric={'Unsubscribes'}
            value={stats?.sendGridStats?.unsubscribes}
            loading={loading}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
