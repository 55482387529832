import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUpdateStepPayload } from '../interfaces/api';
import { IContact, ISequence, IStep, IUser } from '../interfaces/common';
import { IAppSlice } from '../interfaces/store';

const initialState: IAppSlice = {
  token: null,
  user: null,
  contacts: [],
  loadingUserState: false,
  filteredCount: 0,
  totalCount: 0,
  sequences: [],
  loadingSequences: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetToken: (state) => {
      state.token = null;
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = null;
    },
    resetData: (state) => {
      state.contacts = [];
      state.filteredCount = 0;
      state.totalCount = 0;
    },
    saveContacts(state, action: PayloadAction<IContact[]>) {
      state.contacts = action.payload;
    },
    updateContacts(state, action: PayloadAction<IContact[]>) {
      state.contacts = action.payload;
    },
    updateTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    updateFilteredCount(state, action: PayloadAction<number>) {
      state.filteredCount = action.payload;
    },
    updateSequences(state, action: PayloadAction<ISequence[]>) {
      state.sequences = action.payload;
    },
    updateSequence(state, action: PayloadAction<ISequence>) {
      const { id } = action.payload;
      const index = state.sequences.findIndex((seq) => seq.id === id);
      if (index !== -1) {
        state.sequences[index] = action.payload;
      }
    },
    setLoadingSequences(state, action: PayloadAction<boolean>) {
      state.loadingSequences = action.payload;
    },
    updateSequenceStep(state, action: PayloadAction<TUpdateStepPayload>) {
      const { sequence_id, step_id, template } = action.payload;
      const sequence = state.sequences.find((seq) => seq.id === sequence_id);
      if (sequence) {
        const step = sequence.steps.find((step) => step.id === step_id);
        if (step) {
          template.email_subject && (step.email_subject = template.email_subject);
          template.email_body && (step.email_body = template.email_body);
          template.days_after_last_step &&
            (step.days_after_last_step = template.days_after_last_step);
        }
      }
    },
    updateSequenceStore(
      state,
      action: PayloadAction<{ sequence_id: number; name: string | undefined }>
    ) {
      const { sequence_id, name } = action.payload;
      const sequence = state.sequences.find((seq) => seq.id === sequence_id);
      if (sequence) {
        sequence.name = name as string;
      }
    },
    addStepToSequence(state, action: PayloadAction<{ sequenceId: number; step: IStep }>) {
      const { sequenceId, step } = action.payload;
      const sequence = state.sequences.find((seq) => seq.id === sequenceId);
      if (sequence) {
        sequence.steps.push(step);
      }
    }
  }
});

export const {
  resetUser,
  resetData,
  resetToken,
  updateUser,
  updateToken,
  saveContacts,
  updateContacts,
  updateFilteredCount,
  updateTotalCount,
  updateSequences,
  updateSequence,
  setLoadingSequences,
  updateSequenceStep,
  updateSequenceStore,
  addStepToSequence
} = appSlice.actions;
export default appSlice.reducer;
