import axios, { CreateAxiosDefaults } from 'axios';
import {
  TAddSenderEmailsPayload,
  TAddSenderEmailsResponse,
  TAddStepPayload,
  TAddStepResponse,
  TCreateSequencePayload,
  TCreateSequenceResponse,
  TEmailLimitsPayload,
  TEmailLimitsResponse,
  TForgotPasswordPayload,
  TForgotPasswordResponse,
  TGetContactsBySequencePayload,
  TGetContactsBySequenceResponse,
  TGetContactsPayload,
  TGetContactsResponse,
  TGetMailboxesResponse,
  TGetSenderEmailsResponse,
  TGetSequenceByIdResponse,
  TGetSequenceContactPayload,
  TGetSequenceContactsResponse,
  TGetSequenceResponse,
  TGetStatsByDatePayload,
  TGetStatsByDateResponse,
  TGetUserDetailsResponse,
  TLinkMailboxResponse,
  TLoginPayload,
  TLoginResponse,
  TResetPasswordPayload,
  TResetPasswordResponse,
  TSignupPayload,
  TSignupResponse,
  TUpdateContactStatusForSequencePayload,
  TUpdateContactStatusForSequenceResponse,
  TUpdateSequenceNamePayload,
  TUpdateSequencePayload,
  TUpdateSequenceResponse,
  TUpdateStepPayload,
  TUpdateStepResponse,
  TUploadContactsPayload,
  TUploadContactsResponse,
  TUploadContactsToSequencePayload,
  TUploadContactsToSequenceResponse,
  TUploadContactsToSequenceV2Payload,
  TUploadContactsToSequenceV2Response,
  TValidateSendGridApiKeyPayload,
  TValidateSendGridApiKeyResponse,
  TVerifyEmailPayload,
  TVerifyEmailResponse
} from '../interfaces/api';
import { store } from '../store';
import dayjs from 'dayjs';

const defaultOptions: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 300000
};

const client = axios.create(defaultOptions);

client.interceptors.request.use((config) => {
  const token = store.getState().app.token || '';
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const login = async (payload: TLoginPayload): Promise<TLoginResponse> => {
  return await client.post('/login', payload);
};

export const signup = async (payload: TSignupPayload): Promise<TSignupResponse> => {
  return await client.post('/signup', payload);
};

export const verifyEmail = async (payload: TVerifyEmailPayload): Promise<TVerifyEmailResponse> => {
  return await client.post('/confirmUser', payload);
};

export const forgotPassword = async (
  payload: TForgotPasswordPayload
): Promise<TForgotPasswordResponse> => {
  return await client.post('/initiateResetPassword', payload);
};

export const resetPassword = async (
  payload: TResetPasswordPayload
): Promise<TResetPasswordResponse> => {
  return await client.post('/confirmResetPassword', payload);
};

export const getUserDetails = async (): Promise<TGetUserDetailsResponse> => {
  return await client.get('/userdetails');
};

export const validateSendGripApiKey = async (
  payload: TValidateSendGridApiKeyPayload
): Promise<TValidateSendGridApiKeyResponse> => {
  return await client.post('/validateSendGridApiKey', payload);
};

export const getContacts = async (payload: TGetContactsPayload): Promise<TGetContactsResponse> => {
  const params = new URLSearchParams();
  if (payload.pageSize) params.append('pageSize', payload.pageSize.toString());
  if (payload.nextKey) params.append('nextKey', payload.nextKey);
  if (payload.filters) {
    Object.entries(payload.filters).forEach(([key, value]) => {
      if (value) params.append(key, value);
    });
  }
  return await client.get('/contacts', { params });
};

export const uploadContacts = async (
  payload: TUploadContactsPayload
): Promise<TUploadContactsResponse> => {
  return await client.post('/contacts', payload);
};

export const uploadContactsToSequence = async (
  payload: TUploadContactsToSequencePayload
): Promise<TUploadContactsToSequenceResponse> => {
  return await client.post(`/sequences/${payload.sequenceId}/contacts`, payload.contacts);
};

export const uploadContactsToSequenceV2 = async (
  sequenceId: number,
  payload: TUploadContactsToSequenceV2Payload
): Promise<TUploadContactsToSequenceV2Response> => {
  return await client.post(`/sequences/${sequenceId}/contacts/v2`, payload);
};

export const uploadSequenceContactsToSequenceV2 = async (
  sequenceId: number,
  payload: TUploadContactsToSequenceV2Payload
): Promise<TUploadContactsToSequenceV2Response> => {
  return await client.post(`/sequences/${sequenceId}/sequence-contacts/v2`, payload);
};

export const getSequences = async (): Promise<TGetSequenceResponse> => {
  return await client.get('/sequences');
};

export const getSequenceById = async (id: number): Promise<TGetSequenceByIdResponse> => {
  return await client.get(`/sequences/${id}`);
};

export const createSequence = async (
  payload: TCreateSequencePayload
): Promise<TCreateSequenceResponse> => {
  return await client.post('/sequences', payload);
};

export const updateSequence = async (
  payload: TUpdateSequencePayload
): Promise<TUpdateSequenceResponse> => {
  return await client.put(`/sequences/${payload.id}`, payload);
};

export const addStep = async (payload: TAddStepPayload): Promise<TAddStepResponse> => {
  return await client.post(`/sequences/${payload.sequenceId}/steps`, payload.step);
};

export const getSequenceContacts = async (
  payload: TGetSequenceContactPayload
): Promise<TGetSequenceContactsResponse> => {
  const params = new URLSearchParams();
  if (payload?.pageSize) params.append('pageSize', payload.pageSize.toString());
  if (payload?.nextKey) params.append('nextKey', payload.nextKey);
  if (payload?.filters) {
    Object.entries(payload?.filters).forEach(([key, value]) => {
      if (value) params.append(key, value);
    });
  }
  return await client.get(`/sequences/${payload.id}/contacts`, { params });
};

export const getContactsBySequence = async (
  payload: TGetContactsBySequencePayload
): Promise<TGetContactsBySequenceResponse> => {
  const params = new URLSearchParams();
  if (payload.pageSize) params.append('pageSize', payload.pageSize.toString());
  if (payload.nextKey) params.append('nextKey', payload.nextKey);
  if (payload.filters) {
    Object.entries(payload.filters).forEach(([key, value]) => {
      if (value) {
        if (key.toLowerCase() === 'lastupdated') {
          params.append(key, dayjs(value).format('DD-MM-YYYY'));
        } else {
          params.append(key, value);
        }
      }
    });
  }
  return await client.get('/contactsBySequence', { params });
};

export const updateStep = async (payload: TUpdateStepPayload): Promise<TUpdateStepResponse> => {
  return await client.put(
    `/sequences/${payload.sequence_id}/steps/${payload.step_id}`,
    payload.template
  );
};

export const updateSequenceName = async (
  payload: TUpdateSequenceNamePayload
): Promise<TUpdateSequenceResponse> => {
  return await client.put(`/sequences/${payload.sequence_id}`, { name: payload.name });
};

export const linkMailbox = async (): Promise<TLinkMailboxResponse> => {
  return await client.get(`/oauth`);
};

export const getMailboxes = async (): Promise<TGetMailboxesResponse> => {
  return await client.get(`/integrations`);
};

export const addSenderEmails = async (
  payload: TAddSenderEmailsPayload
): Promise<TAddSenderEmailsResponse> => {
  return await client.post(`/sending-emails`, payload);
};

export const updateEmailLimits = async (
  payload: TEmailLimitsPayload
): Promise<TEmailLimitsResponse> => {
  return await client.put(`/email-limit`, payload);
};

export const getSenderEmails = async (): Promise<TGetSenderEmailsResponse> => {
  return await client.get(`/sending-emails`);
};

export const getStatsByDate = async (
  payload: TGetStatsByDatePayload
): Promise<TGetStatsByDateResponse> => {
  const params = new URLSearchParams();
  if (payload.startDate) params.append('startDate', payload.startDate);
  return await client.get(`/sendgrid/statsByStartDate`, { params });
};

export const updateContactStatusForSequence = async (
  payload: TUpdateContactStatusForSequencePayload
): Promise<TUpdateContactStatusForSequenceResponse> => {
  return await client.post(`/sequences/${payload.sequenceId}/updateContactStatus`, payload);
};
