import {
  ExpandMore as ExpandMoreIcon,
  List as ListIcon,
  Refresh as RefreshIcon,
  UploadFile as UploadFileIcon
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  CssVarsProvider,
  Dropdown,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Skeleton,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography
} from '@mui/joy';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddStepModal from '../components/contacts/addStepModal';
import OverviewTab from '../components/sequences/overviewTab';
import SequenceContacts from '../components/sequences/sequenceContacts';
import { ISequence } from '../interfaces/common';
import {
  getSequenceById,
  updateSequenceName,
  updateSequence as updateSequenceApi
} from '../services/api';
import { useAppDispatch, useAppSelector } from '../store';
import { updateSequence, updateSequenceStore } from '../store/app';
import { handleAxiosError } from '../utils/api';
import RepliedEmails from '../components/sequences/repliedEmails';

const SequenceDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useParams();
  const sequenceId = Number(params.id);
  const sequences = useAppSelector((state) => state.app.sequences);
  const sequence = sequences.find((seq) => seq.id === sequenceId);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState<boolean>(false);
  const [sequenceName, setSequenceName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [newStepAdded, setNewStepAdded] = useState<boolean>(false);

  // Fetch sequence by ID
  const fetchSequenceById = useCallback(
    async (id: number) => {
      setIsRefreshing(true);
      try {
        const response = await getSequenceById(id);
        const sequence = response?.data;
        dispatch(updateSequence(sequence));
        setSequenceName(sequence?.name);
        setIsActive(sequence?.status === 'active');
      } catch (error) {
        const errMsg = handleAxiosError(error);
        console.error('Error fetching sequence:', errMsg);
        toast.error(`Error fetching sequence: ${errMsg}`);
        navigate('/sequences', { replace: true });
      } finally {
        setIsRefreshing(false);
      }
    },
    [dispatch, navigate]
  );

  // Fetch sequence when component loads
  useEffect(() => {
    if (!sequenceId) {
      navigate('/sequences', { replace: true });
    }
    fetchSequenceById(sequenceId);
  }, [sequenceId, navigate, fetchSequenceById]);

  const handleGoToUploadContacts = () => {
    if (sequence) navigate('/upload-contacts', { state: { sequenceId: sequence.id } });
  };

  const handleGoToPeoples = () => {
    navigate('/manage-contacts');
  };

  // const handleEditStep = (stepNumber: number) => {
  //   alert(`Editing Step ${stepNumber}`);
  // };

  const handleDeleteStep = (stepNumber: number) => {
    alert(`Deleting Step ${stepNumber}`);
  };

  const handleUpdateSequenceName = async () => {
    if (!sequence) return;
    setIsLoading(true);
    try {
      const payload = {
        sequence_id: sequence.id,
        name: sequenceName
      };

      const response = await updateSequenceName(payload);

      if (response.status === 200) {
        dispatch(
          updateSequenceStore({
            sequence_id: sequence.id,
            name: sequenceName
          })
        );
        toast.success(response.data.message);
        setIsLoading(false);
        fetchSequenceById(sequenceId);
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.error('Error updating sequence name', errMsg);
      toast.error(`Error updating sequence name: ${errMsg}`);
      setIsLoading(false);
    }
  };

  const handleUpdateSequence = async (sequence: ISequence, newStatus: boolean) => {
    setIsRefreshing(true);
    try {
      const payload: ISequence = {
        ...sequence,
        status: newStatus ? 'active' : 'inactive'
      };
      await updateSequenceApi(payload);
      setIsActive(newStatus);
      setIsRefreshing(false);
    } catch (err) {
      setIsRefreshing(false);
      const errMsg = handleAxiosError(err);
      console.error('Error updating sequence active status:', errMsg);
      toast.error(`Error updating sequence active status: ${errMsg}`);
    }
  };

  return (
    <>
      {sequence && (
        <CssVarsProvider>
          <Box sx={{ padding: 4 }}>
            {/* Breadcrumbs */}
            <Breadcrumbs sx={{ mb: 2 }}>
              <Link onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                Home
              </Link>
              <Link onClick={() => navigate('/manage-sequence')} sx={{ cursor: 'pointer' }}>
                Sequences
              </Link>
              <Typography>{sequence.name}</Typography>
            </Breadcrumbs>

            {/* Header with Actions */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
                gap: 2
              }}>
              <Typography level="h2">{sequence.name}</Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton
                  size="sm"
                  color="primary"
                  variant="solid"
                  aria-label="Refresh Sequence"
                  onClick={() => fetchSequenceById(sequenceId)}
                  loading={isRefreshing}>
                  <RefreshIcon />
                </IconButton>
                {/* Add Contacts Menu */}
                <Dropdown>
                  <MenuButton variant="solid" color="primary" endDecorator={<ExpandMoreIcon />}>
                    Add Contacts
                  </MenuButton>
                  <Menu placement="bottom-start">
                    <MenuItem onClick={handleGoToUploadContacts}>
                      <UploadFileIcon fontSize="small" sx={{ mr: 1 }} />
                      Upload CSV
                    </MenuItem>
                    <MenuItem onClick={handleGoToPeoples}>
                      <ListIcon fontSize="small" sx={{ mr: 1 }} />
                      Select List
                    </MenuItem>
                  </Menu>
                </Dropdown>

                {/* Add Step Button */}
                <Button variant="solid" color="primary" onClick={() => setIsAddStepModalOpen(true)}>
                  Add Step
                </Button>

                {/* Active Switch */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="body-md">Active</Typography>
                  {/* <Switch
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    color={isActive ? 'primary' : 'neutral'}
                  /> */}
                  <Switch
                    checked={isActive}
                    color={isActive ? 'primary' : 'neutral'}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleUpdateSequence(sequence, e.target.checked);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {isRefreshing ? (
              // Render Skeleton Loader
              <Box>
                {/* Skeleton for Tabs */}
                <Skeleton variant="rectangular" height={40} sx={{ mb: 3 }} />

                {/* Skeleton for Tab Content */}
                <Skeleton variant="rectangular" height={400} />
              </Box>
            ) : (
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(Number(newValue))}
                sx={{ mb: 3 }}>
                <TabList variant="plain" sx={{ gap: 2, overflowX: 'auto' }}>
                  <Tab color={selectedTab === 0 ? 'primary' : 'neutral'}>Overview</Tab>
                  <Tab color={selectedTab === 1 ? 'primary' : 'neutral'}>Contacts</Tab>
                  <Tab color={selectedTab === 2 ? 'primary' : 'neutral'}>Replied Emails</Tab>
                  <Tab color={selectedTab === 3 ? 'primary' : 'neutral'}>Settings</Tab>
                </TabList>

                {/* Overview Tab */}
                <TabPanel value={0}>
                  <OverviewTab
                    sequence={sequence}
                    newStepAdded={newStepAdded}
                    setNewStepAdded={setNewStepAdded}
                    handleDelete={handleDeleteStep}
                  />
                </TabPanel>

                <TabPanel value={1}>
                  <SequenceContacts sequenceId={sequence.id} />
                </TabPanel>

                <TabPanel value={2}>
                  <RepliedEmails sequenceId={sequence.id} />
                </TabPanel>

                <TabPanel value={3}>
                  <Box
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      maxWidth: 400,
                      mt: 2
                    }}>
                    <FormControl>
                      <FormLabel>Sequence Name</FormLabel>
                      <Input
                        defaultValue={sequence.name}
                        value={sequenceName}
                        onChange={(e) => setSequenceName(e.target.value)}
                        placeholder="Enter new sequence name"
                        sx={{ mt: 1 }}
                      />
                    </FormControl>

                    <Button
                      onClick={handleUpdateSequenceName}
                      loading={isLoading}
                      variant="solid"
                      color="primary">
                      Update Name
                    </Button>
                  </Box>
                </TabPanel>
              </Tabs>
            )}

            <AddStepModal
              update={async () => {
                await fetchSequenceById(sequenceId);
                setNewStepAdded(true);
              }}
              open={isAddStepModalOpen}
              onClose={() => setIsAddStepModalOpen(false)}
              sequence={sequence}
            />
          </Box>
        </CssVarsProvider>
      )}
    </>
  );
};

export default SequenceDetailPage;
