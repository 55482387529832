import { Box, Button, Typography, ListItemDecorator, Grid } from '@mui/joy';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { resetData, resetToken, resetUser } from '../../store/app';
import LogoutIcon from '@mui/icons-material/Logout';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useEffect, useState } from 'react';
import { Mail } from '@mui/icons-material';
import { unsetShakeUser } from '../../helpers/shake';

interface DrawerProps {
  open: boolean;
}

export default function Drawer({ open }: DrawerProps) {
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(resetToken());
    dispatch(resetUser());
    dispatch(resetData());
    unsetShakeUser();
    navigate('/');
  };

  useEffect(() => {
    if (location?.pathname) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.level1',
        height: '100vh',
        borderRight: '1px solid',
        borderColor: 'divider'
      }}>
      <Box
        sx={{
          width: open ? '240px' : '60px',
          transition: 'width 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: open ? '0.5rem' : '0rem',
                justifyContent: open ? 'center' : 'start'
              }}>
              <SwapHorizIcon color="primary" />
              <Typography
                level="h4"
                component="h1"
                color="primary"
                sx={{
                  textWrap: 'nowrap',
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  transition: 'opacity 0.3s ease, transform 0.3s ease'
                }}>
                Engage Switch
              </Typography>
            </Grid>
          </Link>
          <Link
            to="/dashboard"
            className={`drawer-link ${currentPath === '/' ? 'active-link' : ''}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              marginTop: '1.5rem',
              padding: '0.25rem'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: open ? '0.5rem' : '0rem' }}>
              <ListItemDecorator>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#636B74"
                  width="20px"
                  height="20px"
                  viewBox="0 0 36 36"
                  version="1.1"
                  preserveAspectRatio="xMidYMid meet">
                  <title>home-solid</title>
                  <path
                    className="clr-i-solid clr-i-solid-path-1"
                    d="M33,19a1,1,0,0,1-.71-.29L18,4.41,3.71,18.71a1,1,0,0,1-1.41-1.41l15-15a1,1,0,0,1,1.41,0l15,15A1,1,0,0,1,33,19Z"
                  />
                  <path
                    className="clr-i-solid clr-i-solid-path-2"
                    d="M18,7.79,6,19.83V32a2,2,0,0,0,2,2h7V24h6V34h7a2,2,0,0,0,2-2V19.76Z"
                  />
                  <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
                </svg>
              </ListItemDecorator>
              <Typography
                level="body-md"
                sx={{
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  transition: 'opacity 0.3s ease, transform 0.3s ease'
                }}>
                Home
              </Typography>
            </Box>
          </Link>
          <Typography
            level="body-sm"
            color="neutral"
            sx={{
              borderBottom: '1px solid #636B74',
              padding: '0.25rem 0rem',
              marginTop: '1.25rem',
              fontSize: open ? 'inherit' : '0px',
              transition: 'font-size 0.3s ease'
            }}>
            Engage
          </Typography>
          <Link
            to="/manage-contacts"
            className={`drawer-link ${
              currentPath === '/manage-contacts' || currentPath === '/upload-contacts'
                ? 'active-link'
                : ''
            }`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              marginTop: '0.75rem',
              padding: '0.25rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: open ? '0.5rem' : '0rem'
              }}>
              <ListItemDecorator>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#636B74"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  version="1.1">
                  <title>contacts</title>
                  <path d="M2.016 28v2.016q0 0.832 0.576 1.408t1.408 0.576v-4h-1.984zM2.016 25.024q0 0.384 0.288 0.704t0.704 0.288h1.984q0.416 0 0.704-0.288t0.32-0.704-0.32-0.704-0.704-0.32h-1.984q-0.416 0-0.704 0.32t-0.288 0.704zM2.016 22.016h1.984v-12h-1.984v12zM2.016 7.008q0 0.416 0.288 0.704t0.704 0.288h1.984q0.416 0 0.704-0.288t0.32-0.704-0.32-0.704-0.704-0.288h-1.984q-0.416 0-0.704 0.288t-0.288 0.704zM2.016 4h1.984v-4q-0.832 0-1.408 0.608t-0.576 1.408v1.984zM6.016 28v2.016q0 0.832 0.576 1.408t1.408 0.576h20q0.832 0 1.408-0.576t0.608-1.408v-28q0-0.832-0.608-1.408t-1.408-0.608h-20q-0.832 0-1.408 0.608t-0.576 1.408v1.984q0.8 0 1.408 0.608t0.576 1.408v1.984q0 0.832-0.576 1.44t-1.408 0.576v12q0.8 0 1.408 0.576t0.576 1.408v2.016q0 0.832-0.576 1.408t-1.408 0.576zM12 21.024q0.224-1.344 1.056-2.464t2.048-1.792q-1.088-1.152-1.088-2.752v-2.016q0-1.632 1.152-2.816t2.848-1.184 2.816 1.184 1.184 2.816v2.016q0 1.6-1.12 2.752 1.184 0.672 2.048 1.792t1.056 2.464q0 1.248-0.864 2.112t-2.144 0.864h-5.984q-1.248 0-2.144-0.864t-0.864-2.112z" />
                </svg>
              </ListItemDecorator>
              <Typography
                level="body-md"
                sx={{
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  transition: 'opacity 0.3s ease, transform 0.3s ease'
                }}>
                People
              </Typography>
            </Box>
          </Link>

          <Link
            to="/manage-sequence"
            className={`drawer-link ${
              currentPath.includes('/manage-sequence') ? 'active-link' : ''
            }`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              marginTop: '0.75rem',
              padding: '0.25rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: open ? '0.5rem' : '0rem'
              }}>
              <ListItemDecorator>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <path d="M10.25 4.00003C10.25 3.69074 10.0602 3.41317 9.77191 3.30105C9.48366 3.18892 9.15614 3.26524 8.94715 3.49324L3.44715 9.49324C3.24617 9.71248 3.19374 10.0298 3.3135 10.302C3.43327 10.5743 3.70259 10.75 4.00002 10.75H20C20.4142 10.75 20.75 10.4142 20.75 10C20.75 9.58582 20.4142 9.25003 20 9.25003L10.25 9.25003V4.00003Z" />{' '}
                    <path d="M13.75 20L13.75 14.75H4.00002C3.5858 14.75 3.25002 14.4142 3.25002 14C3.25002 13.5858 3.5858 13.25 4.00002 13.25L20 13.25C20.2974 13.25 20.5668 13.4258 20.6865 13.698C20.8063 13.9703 20.7539 14.2876 20.5529 14.5068L15.0529 20.5068C14.8439 20.7348 14.5164 20.8111 14.2281 20.699C13.9399 20.5869 13.75 20.3093 13.75 20Z" />{' '}
                  </g>
                </svg>
              </ListItemDecorator>
              <Typography
                level="body-md"
                sx={{
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  transition: 'opacity 0.3s ease, transform 0.3s ease'
                }}>
                Sequence
              </Typography>
            </Box>
          </Link>
          <Typography
            level="body-sm"
            color="neutral"
            sx={{
              borderBottom: '1px solid #636B74',
              padding: '0.25rem 0rem',
              marginTop: '1.25rem',
              fontSize: open ? 'inherit' : '0px',
              transition: 'font-size 0.3s ease'
            }}>
            Tools
          </Typography>
          <Link
            to="/sendgrid-integration"
            className={`drawer-link ${
              currentPath === '/sendgrid-integration' ? 'active-link' : ''
            }`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              marginTop: '0.75rem',
              padding: '0.25rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: open ? '0.5rem' : '0rem'
              }}>
              <ListItemDecorator>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#636B74"
                  width="20px"
                  height="20px"
                  viewBox="0 0 1024 1024"
                  className="icon">
                  <path d="M917.7 148.8l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 0 0-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 0 0 0 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3 2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM578.9 546.7a8.03 8.03 0 0 0-11.3 0L501 613.3 410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 0 0-11.3 0L363 475.3l-43-43a7.85 7.85 0 0 0-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2c-68.9 68.9-77 175.7-24.3 253.5l-76.1 76.1a8.03 8.03 0 0 0 0 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3 51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43 66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2z" />
                </svg>
              </ListItemDecorator>
              <Typography
                level="body-md"
                sx={{
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  fontSize: open ? 'inherit' : '0px',
                  transition: 'opacity 0.3s ease, transform 0.3s ease, font-size 0.3s ease'
                }}>
                Sendgrid API
              </Typography>
            </Box>
          </Link>
          <Link
            to="/manage-mailboxes"
            className={`drawer-link ${currentPath === '/manage-mailboxes' ? 'active-link' : ''}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              marginTop: '0.75rem',
              padding: '0.25rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: open ? '0.5rem' : '0rem'
              }}>
              <ListItemDecorator>
                <Mail />
              </ListItemDecorator>
              <Typography
                level="body-md"
                sx={{
                  opacity: open ? 1 : 0,
                  transform: open ? 'translateX(0)' : 'translateX(-20px)',
                  fontSize: open ? 'inherit' : '0px',
                  transition: 'opacity 0.3s ease, transform 0.3s ease, font-size 0.3s ease'
                }}>
                Mailboxes
              </Typography>
            </Box>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem' }}>
          {open ? (
            <Button
              onClick={handleLogout}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                display: 'flex',
                alignItems: 'center',
                textWrap: 'nowrap',
                gap: '0.5rem',
                justifyContent: 'center'
              }}>
              <LogoutIcon />
              Log out
            </Button>
          ) : (
            <ListItemDecorator
              onClick={handleLogout}
              sx={{
                padding: '6px 0px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer'
              }}>
              <LogoutIcon />
            </ListItemDecorator>
          )}
        </Box>
      </Box>
    </Box>
  );
}
