import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, Input, Modal, Option, Select, Sheet, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { addStep } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import { ISequence } from '../../interfaces/common';

interface AddStepModalProps {
  open: boolean;
  onClose: () => void;
  sequence: ISequence;
  update: () => void;
}

const AddStepModal: React.FC<AddStepModalProps> = ({ update, open, onClose, sequence }) => {
  const [quantity, setQuantity] = useState<number>(0);
  const [time, setTime] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const sequenceSteps = sequence?.steps?.length;
  const stepNumber = sequenceSteps + 1;
  const sequenceId = sequence?.id;

  const createChecks = () => {
    if (sequenceSteps === 0) {
      return true;
    } else {
      if (quantity > 0) {
        return true;
      } else {
        toast.error('Days can only be 1 or more.');
        return false;
      }
    }
  };

  const handleCreate = async () => {
    if (createChecks()) {
      setIsCreating(true);
      const payload = {
        sequenceId: sequenceId,
        step: {
          stepOrder: stepNumber,
          daysAfterLastStep: stepNumber === 1 ? 0 : quantity,
          emailSubject: '',
          emailBody: ''
        }
      };

      try {
        const response = await addStep(payload);
        if (response.status === 201) {
          toast.success(response.data.message);
          onClose();
          update();
        }
        setIsCreating(false);
      } catch (err) {
        setIsCreating(false);
        const errMsg = handleAxiosError(err);
        console.warn('Error creating step', errMsg);
        toast.error(`Error creating step: ${errMsg}`);
      }
    }
  };

  const handleChangeOption = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    if (newValue) {
      setTime(newValue);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Sheet
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          padding: 4,
          boxShadow: 'lg',
          borderRadius: 'md',
          bgcolor: 'background.level1',
          border: '1px solid',
          borderColor: 'divider'
        }}>
        <Typography level="h4" component="h2">
          Add Step {stepNumber}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: 2,
            marginTop: 2,
            boxShadow: 'sm',
            borderRadius: 'md'
          }}>
          <Box
            sx={{
              width: '1rem',
              height: '1rem',
              padding: 2,
              borderRadius: '50%',
              bgcolor: '#eaf2ff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <EmailIcon color="primary" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginLeft: 2
            }}>
            <Typography level="body-sm" component="h4">
              Automatic email
            </Typography>
            <Typography level="body-xs" color="neutral">
              Emails are delivered automatically.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, marginBottom: 3 }}>
          <Typography level="body-sm" component="h4">
            When to start this step
          </Typography>
          <Typography level="body-xs" color="neutral">
            Set the amount of time you would like to wait between steps.
          </Typography>
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <Input
              slotProps={{ input: { maxLength: 3 } }}
              placeholder="0"
              sx={{ width: '4rem' }}
              value={quantity}
              type="number"
              readOnly={stepNumber === 1 ? true : false}
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
            <Select
              sx={{ marginLeft: 1, width: '7rem' }}
              defaultValue="days"
              placeholder="days"
              value={time}
              onChange={handleChangeOption}>
              <Option value="days">Days</Option>
            </Select>
            <Typography sx={{ marginLeft: 1 }} level="body-xs" color="neutral">
              after the previous step
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="solid" color="primary" onClick={handleCreate} loading={isCreating}>
            Continue
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default AddStepModal;
