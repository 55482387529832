import { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, Card, FormControl, FormHelperText, Grid, Input, Typography } from '@mui/joy';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';
import { SenderEmailSchema } from '../../constants/yup';
import { addSenderEmails } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import { useAppSelector } from '../../store';
import { ISenderEmails, ISignatures } from '../../interfaces/common';
import { TAddSenderEmailsPayload } from '../../interfaces/api';

interface IProps {
  senderEmails: ISenderEmails & ISignatures;
}

export const SenderEmails = ({ senderEmails }: IProps) => {
  const user = useAppSelector((state) => state.app.user);

  const [initialValues, setInitialValues] = useState({
    senderEmail1: senderEmails?.email1 || '',
    senderEmail2: senderEmails?.email2 || '',
    signature1: senderEmails?.signature1 || '',
    signature2: senderEmails?.signature2 || ''
  });

  useEffect(() => {
    if (senderEmails) {
      setInitialValues({
        senderEmail1: senderEmails.email1,
        senderEmail2: senderEmails.email2,
        signature1: senderEmails.signature1,
        signature2: senderEmails.signature2
      });
    }
  }, [senderEmails]);

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting, setErrors }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        const payload: TAddSenderEmailsPayload = {
          email1: values.senderEmail1.trim(),
          email2: values.senderEmail2.trim(),
          signature1: values.signature1?.trim(),
          signature2: values.signature2?.trim()
        };
        const response = await addSenderEmails(payload);
        if (response.status === 200) toast.success('Record updated successfully.');
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      toast.error(`Error adding sender emails: ${errMsg}`);
      setErrors({ senderEmail1: errMsg, senderEmail2: errMsg });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card variant="outlined">
      <Typography level="title-md">Sender Emails</Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SenderEmailSchema}
        onSubmit={handleSubmit}>
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            {/* Sender Email 1 */}
            <FormControl sx={{ mb: 2 }}>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                Sender Email 1
              </Typography>
              <Input
                name="senderEmail1"
                value={values.senderEmail1}
                onChange={(e) => setFieldValue('senderEmail1', e.target.value)}
                error={touched.senderEmail1 && !!errors.senderEmail1}
                placeholder="Enter Sender Email 1"
              />
              {touched.senderEmail1 && errors.senderEmail1 && (
                <FormHelperText>{errors.senderEmail1}</FormHelperText>
              )}
            </FormControl>

            {/* Signature Email 1 */}
            <FormControl sx={{ mb: 2 }}>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                Signature Email 1
              </Typography>
              <Box sx={{ border: '1px solid #c4c4c4', borderRadius: '4px' }}>
                <ReactQuill
                  className="quill-editor"
                  theme="snow"
                  value={values.signature1 || ''}
                  onChange={(value) => setFieldValue('signature1', value)}
                />
              </Box>
            </FormControl>

            {/* Sender Email 2 */}
            <FormControl sx={{ mb: 2 }}>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                Sender Email 2
              </Typography>
              <Input
                name="senderEmail2"
                value={values.senderEmail2}
                onChange={(e) => setFieldValue('senderEmail2', e.target.value)}
                error={touched.senderEmail2 && !!errors.senderEmail2}
                placeholder="Enter Sender Email 2"
              />
              {touched.senderEmail2 && errors.senderEmail2 && (
                <FormHelperText>{errors.senderEmail2}</FormHelperText>
              )}
            </FormControl>

            {/* Signature Email 2 */}
            <FormControl sx={{ mb: 2 }}>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                Signature Email 2
              </Typography>
              <Box sx={{ border: '1px solid #c4c4c4', borderRadius: '4px' }}>
                <ReactQuill
                  className="quill-editor"
                  theme="snow"
                  value={values.signature2 || ''}
                  onChange={(value) => setFieldValue('signature2', value)}
                />
              </Box>
            </FormControl>

            {/* Submit Button */}
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid>
                <Button type="submit" loading={isSubmitting}>
                  Add Sender Emails
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
