import { Button, Card, FormControl, FormHelperText, Grid, Input, Typography } from '@mui/joy';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TValidateSendGridSchema,
  ValidateSendGridInitialValues,
  ValidateSendGridSchema
} from '../../constants/yup';
import { TValidateSendGridApiKeyPayload } from '../../interfaces/api';
import { getUserDetails, validateSendGripApiKey } from '../../services/api';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateUser } from '../../store/app';
import { handleAxiosError } from '../../utils/api';

export function SendgridAccount() {
  const user = useAppSelector((state) => state.app.user);
  const placeholder = user?.company?.sendgrid_api_key || '';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleUpdateApiKey = async (
    values: TValidateSendGridSchema,
    { setSubmitting, setErrors }: FormikHelpers<TValidateSendGridSchema>
  ) => {
    try {
      setSubmitting(true);
      if (user) {
        const payload: TValidateSendGridApiKeyPayload = {
          apiKey: values.apiKey,
          companyId: user.id
        };

        const response = await validateSendGripApiKey(payload);

        if (response.status === 200) {
          toast.success('Validation Successful');

          const userResponse = await getUserDetails();
          const updatedUserData = userResponse.data;

          if (updatedUserData) {
            dispatch(updateUser(updatedUserData));
            navigate('/dashboard');
          } else {
            throw new Error('Updated user data not found in response');
          }
        } else {
          throw new Error('Failed to validate API key');
        }
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error validating API key', errMsg);
      toast.error(`Error validating API key: ${errMsg}`);
      setErrors({ apiKey: errMsg });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteApiKey = () => {};

  return (
    <Card variant="outlined">
      <Typography level="title-md">Sendgrid account</Typography>
      <Formik
        initialValues={ValidateSendGridInitialValues}
        validationSchema={ValidateSendGridSchema}
        onSubmit={handleUpdateApiKey}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              Sendgrid API key
            </Typography>
            <FormControl sx={{ mb: 2 }}>
              <Field
                as={Input}
                name="apiKey"
                placeholder={placeholder}
                error={touched.apiKey && errors.apiKey}
              />
              {touched.apiKey && errors.apiKey && <FormHelperText>{errors.apiKey}</FormHelperText>}
            </FormControl>

            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid>
                <Button type="submit" loading={isSubmitting} sx={{ alignSelf: 'flex-start' }}>
                  Update API key
                </Button>
              </Grid>
              {placeholder && (
                <Grid>
                  <Button
                    type="button"
                    onClick={handleDeleteApiKey}
                    color="danger"
                    disabled={isSubmitting}
                    sx={{ alignSelf: 'flex-start' }}>
                    Delete API key
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
