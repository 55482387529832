import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CssVarsProvider,
  Divider,
  Skeleton,
  Switch,
  Typography
} from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateSequenceModal from '../components/contacts/createSequenceModal';
import { ISequence } from '../interfaces/common';
import { createSequence, getSequences, updateSequence } from '../services/api';
import { useAppDispatch, useAppSelector } from '../store';
import { setLoadingSequences, updateSequences } from '../store/app';
import { handleAxiosError } from '../utils/api';

const ManageSequencePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const sequences = useAppSelector((state) => state.app.sequences) || [];
  const loadingSequences = useAppSelector((state) => state.app.loadingSequences);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);

  useEffect(() => {
    const fetchSequences = async () => {
      try {
        dispatch(updateSequences([]));
        dispatch(setLoadingSequences(true));
        const response = await getSequences();
        dispatch(updateSequences(response.data.sequences));
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error fetching sequences: ${errMsg}`);
        console.error('Error fetching sequences:', errMsg);
      } finally {
        dispatch(setLoadingSequences(false));
      }
    };

    fetchSequences();
  }, [dispatch, triggerUpdate]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSequenceClick = (sequence: ISequence) => {
    navigate(`/manage-sequence/${sequence.id}`);
  };

  const handleCreateSequence = async (sequenceName: string) => {
    setIsCreating(true);
    try {
      const newSequence: ISequence = {
        id: sequences.length + 1,
        name: sequenceName,
        status: 'active',
        last_event: '',
        last_updated: new Date().toLocaleDateString(),
        counts: {
          scheduled: 0,
          delivered: 0,
          open: 0,
          reply: 0,
          click: 0,
          bounced: 0,
          spam_blocked: 0,
          opt_out: 0
        },
        steps: []
      };

      const payload = {
        name: sequenceName
      };

      const response = await createSequence(payload);

      const seqId = response.data.sequenceId;
      dispatch(updateSequences([...sequences, { ...newSequence, id: seqId }]));

      setIsCreating(false);
      toast.success('Sequence created successfully');
      setIsModalOpen(false);
    } catch (err) {
      setIsCreating(false);
      const errMsg = handleAxiosError(err);
      console.error('Error creating sequence:', errMsg);
      toast.error(`Error creating sequence: ${errMsg}`);
    }
  };

  const handleUpdateSequence = async (sequence: ISequence, newStatus: boolean) => {
    setIsUpdating(true);
    try {
      const payload: ISequence = {
        ...sequence,
        status: newStatus ? 'active' : 'inactive'
      };
      await updateSequence(payload);
      setIsUpdating(false);
      setTriggerUpdate((prev) => !prev);
    } catch (err) {
      setIsUpdating(false);
      const errMsg = handleAxiosError(err);
      console.error('Error updating sequence active status:', errMsg);
      toast.error(`Error updating sequence active status: ${errMsg}`);
    }
  };

  return (
    <CssVarsProvider>
      <Box sx={{ p: { xs: 2, md: 3 }, maxWidth: '1200px', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: { xs: 2, md: 3 },
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 2, md: 0 }
          }}>
          <Typography level="h3" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
            Manage Sequences
          </Typography>
          <Button
            variant="solid"
            color="primary"
            startDecorator={<AddIcon />}
            onClick={handleOpenModal}
            sx={{ width: { xs: '100%', md: 'auto' } }}>
            Create Sequence
          </Button>
        </Box>

        <CreateSequenceModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleCreateSequence}
          isLoading={isCreating || isUpdating}
        />

        {loadingSequences ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)'
              },
              gap: 2
            }}>
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} variant="rectangular" height={200} />
            ))}
          </Box>
        ) : sequences.length === 0 ? (
          <Typography level="body-md" sx={{ textAlign: 'center', py: 4 }}>
            No sequences created yet.
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)'
              },
              gap: 2
            }}>
            {sequences.map((sequence) => (
              <Card
                key={sequence.id}
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  '&:hover': { boxShadow: 'md' }
                }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}>
                    <Typography level="h4">{sequence.name}</Typography>
                    <Switch
                      checked={sequence.status === 'active'}
                      color={sequence.status === 'active' ? 'primary' : 'neutral'}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        handleUpdateSequence(sequence, e.target.checked);
                      }}
                    />
                  </Box>
                  <Typography level="body-sm" color="primary">
                    {sequence.steps.length} Steps • Last updated on{' '}
                    {new Date(sequence.last_updated).toLocaleDateString()}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      columnGap: 3,
                      rowGap: 1
                    }}>
                    {Object.entries(sequence.counts)
                      .filter(([key]) => key !== 'processed')
                      .map(([key, value]) => (
                        <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography level="body-md" color="neutral">
                            {key.replace(/_/g, ' ')}
                          </Typography>
                          <Typography level="body-md">{value}</Typography>
                        </Box>
                      ))}
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Button
                    variant="outlined"
                    // startDecorator={<EditIcon />}
                    onClick={() => {
                      handleSequenceClick(sequence);
                    }}>
                    View Details
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </CssVarsProvider>
  );
};

export default ManageSequencePage;
