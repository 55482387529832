import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  Option,
  Select,
  Sheet,
  Skeleton,
  Snackbar,
  Table,
  Typography,
  Grid,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem
} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IApiContact, ISequence } from '../../interfaces/common';
import { getSequenceContacts, uploadSequenceContactsToSequenceV2 } from '../../services/api';
import { handleAxiosError } from '../../utils/api';
import dayjs from 'dayjs';
import FilterPanel from '../contacts/filterPanel';
import { useAppSelector } from '../../store';
import AddContactsToSequenceModal from '../contacts/addSelectedContactsToSequence';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TUploadSequenceContactsToSequenceV2Payload } from '../../interfaces/api';

interface ISequenceContacts {
  sequenceId: number;
}

type SortConfig = {
  key: keyof IApiContact;
  direction: 'asc' | 'desc';
} | null;

const headersMap: { [key in keyof IApiContact]: string } = {
  ContactId: 'Contact ID',
  FirstName: 'First Name',
  LastName: 'Last Name',
  JobTitle: 'Job Title',
  CompanyName: 'Company Name',
  Email: 'Email',
  Phone: 'Phone',
  Industry: 'Industry',
  LinkedIn: 'LinkedIn',
  CompanySize: 'Company Size',
  UserId: 'User ID',
  CompanyId: 'Company ID',
  Status: 'Status',
  CurrentStepId: 'Current Step',
  LastEvent: 'Last Event',
  LastUpdated: 'Last Updated',
  SequenceId: 'Sequence ID',
  FileName: 'File Name'
};

const tableFields: (keyof IApiContact)[] = [
  'FirstName',
  'LastName',
  'Email',
  'JobTitle',
  'CompanyName',
  'Phone',
  'Industry',
  'Status',
  'CurrentStepId',
  'LastEvent',
  'LastUpdated',
  'FileName'
];

const fieldsToCapitalize: (keyof IApiContact)[] = [
  'FirstName',
  'LastName',
  'CompanyName',
  'JobTitle',
  'Industry'
];

const SequenceContacts: React.FC<ISequenceContacts> = ({ sequenceId }) => {
  const [contacts, setContacts] = useState<IApiContact[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortConfig, setSortConfig] = useState<SortConfig>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(0);
  const [selectedContacts, setSelectedContacts] = useState<IApiContact[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [confirmAddModalOpen, setConfirmAddModalOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<ISequence | null>(null);

  const navigate = useNavigate();

  const sequences = useAppSelector((state) => state.app.sequences);
  const otherSequences = useMemo(
    () => sequences.filter((seq) => seq.id !== sequenceId),
    [sequences, sequenceId]
  );

  const nextKey = useRef<string | undefined>(undefined);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const visibleTableFields = isSmallScreen
    ? (['FirstName', 'LastName', 'Email', 'Status'] as (keyof IApiContact)[])
    : tableFields;

  const filtersApplied = Object.keys(filters).length > 0;

  const handleInputChange = (key: string, value: string) => {
    setInputValues({ ...inputValues, [key]: value });
  };

  const handleAddFilter = (key: string) => {
    const value = inputValues[key]?.trim();
    if (value) {
      setFilters((prevState) => ({
        ...prevState,
        [key]: value.toLowerCase()
      }));
      setInputValues({ ...inputValues, [key]: '' });
    }
  };

  const handleDeleteFilter = (key: string) => {
    setFilters((prevState) => {
      const newFilters = { ...prevState };
      delete newFilters[key];
      return newFilters;
    });
  };

  const handleApplyFilters = async () => {
    nextKey.current = undefined;
    setContacts([]);
    setPage(0);
    setHasMore(true);
    setToggleRefresh((prev) => !prev);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      if (!hasMore) return;
      setLoading(true);
      try {
        const response = await getSequenceContacts({
          id: sequenceId,
          nextKey: nextKey.current,
          pageSize: rowsPerPage,
          filters
        });
        const data = response.data;
        setTotalCount(data.totalCount ?? 0);
        if (nextKey.current) {
          setContacts((prev) => [...prev, ...data.contacts]);
        } else {
          setContacts(data.contacts);
        }
        if (data.nextKey) {
          nextKey.current = data.nextKey;
        } else {
          setHasMore(false);
        }
      } catch (err) {
        const errMsg = handleAxiosError(err);
        console.error('Error fetching contacts:', errMsg);
        toast.error('Failed to load contacts.');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceId, toggleRefresh]);

  const handleRefreshContacts = async () => {
    setFilters({});
    setInputValues({});
    setContacts([]);
    setRowsPerPage(100);
    setPage(0);
    setHasMore(true);
    nextKey.current = undefined;
    setToggleRefresh((prev) => !prev);
  };

  const handleSort = (key: keyof IApiContact) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedContacts = [...contacts].sort((a, b) => {
      const aValue = a[key] || '';
      const bValue = b[key] || '';
      return direction === 'asc'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setSortConfig({ key, direction });
    setContacts(sortedContacts);
  };

  const paginatedContacts = useMemo(() => {
    return contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [contacts, page, rowsPerPage]);

  const handleSelectContact = (contact: IApiContact) => {
    setSelectedContacts((prevSelected) => {
      const isSelected = prevSelected.some((c) => c.ContactId === contact.ContactId);
      if (isSelected) {
        return prevSelected.filter((c) => c.ContactId !== contact.ContactId);
      } else {
        return [...prevSelected, contact];
      }
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedContacts(paginatedContacts);
    } else {
      setSelectedContacts([]);
    }
  };

  // const handleDeleteSelected = () => {
  //   const remainingContacts = contacts.filter(
  //     (contact) => !selectedContacts.some((c) => c.ContactId === contact.ContactId)
  //   );
  //   setContacts(remainingContacts);
  //   setFilteredContacts(remainingContacts);
  //   setSelectedContacts([]);
  //   toast.success('Selected contacts deleted.');
  // };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setHasMore(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setContacts([]);
    setToggleRefresh((prev) => !prev);
  };

  const handleChangePage = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setPage((prev) => prev - 1);
    } else {
      const contactsToShow = page * rowsPerPage + rowsPerPage;
      if (contactsToShow < contacts.length) {
        setPage((prev) => prev + 1);
      } else if (contactsToShow >= contacts.length && hasMore) {
        setPage((prev) => prev + 1);
        setToggleRefresh((prev) => !prev);
      } else {
        toast.info('No more contacts to fetch');
      }
    }
  };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getDisplayValue = (field: keyof IApiContact, value: string | number | null) => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      return 'N/A';
    }
    if (field.toLowerCase().includes('date')) {
      return dayjs(value).format('YYYY-MM-DD');
    } else if (fieldsToCapitalize.includes(field)) {
      return capitalizeWords(value.toString());
    }
    return value;
  };

  const handleAddContactsToSequence = async () => {
    if (!selectedSequence) {
      toast.error('No sequence selected');
      return;
    }

    if (selectedContacts.length === 0) {
      toast.error('No contacts selected');
      return;
    }

    try {
      setIsUploading(true);
      const payload: TUploadSequenceContactsToSequenceV2Payload = {
        sourceSequenceId: `${sequenceId}`,
        filters: Object.keys(filters).length > 0 ? filters : undefined
      };
      const response = await uploadSequenceContactsToSequenceV2(selectedSequence.id, payload);
      if (response.status === 201) {
        const numberOfContactsAdded = response?.data?.totalContactsAdded;
        toast.success(`${numberOfContactsAdded} Contacts Added To ${selectedSequence.name}`);
        setConfirmAddModalOpen(false);
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.error('Failed to upload contacts to sequence', errMsg);
      toast.error(errMsg);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSequenceClick = (sequence: ISequence) => {
    setSelectedSequence(sequence);
    setConfirmAddModalOpen(true);
  };

  const handleConfirmCloseModal = () => {
    setConfirmAddModalOpen(false);
  };

  const handleSelectStatus = (status: string) => {
    setInputValues((prev) => ({ ...prev, Status: status }));
    setFilters((prev) => ({ ...prev, Status: status }));
  };

  return (
    <Box>
      <AddContactsToSequenceModal
        open={confirmAddModalOpen}
        onClose={handleConfirmCloseModal}
        onSubmit={handleAddContactsToSequence}
        contacts={filtersApplied ? 'filtered' : 'all'}
        sequenceName={selectedSequence?.name || ''}
        loading={isUploading}
      />
      <Snackbar
        open={loading}
        variant="solid"
        color="primary"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        Loading contacts...
      </Snackbar>

      <Grid container spacing={2}>
        <Grid xs={12} md={3}>
          <FilterPanel
            filters={filters}
            inputValues={inputValues}
            handleInputChange={handleInputChange}
            handleAddFilter={handleAddFilter}
            handleDeleteFilter={handleDeleteFilter}
            handleApplyFilters={handleApplyFilters}
            handleResetFilters={handleRefreshContacts}
            handleSelectStatus={handleSelectStatus}
            isApplyDisabled={!filtersApplied}
            loading={loading}
            tableFields={tableFields}
            headersMap={headersMap}
            filtersApplied={filtersApplied}
            componentTypes={{
              LastUpdated: 'Date',
              Status: 'List'
            }}
          />
        </Grid>

        <Grid xs={12} md={9}>
          <Sheet
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: 'md',
              minHeight: 500,
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                flexWrap: 'wrap',
                gap: 1
              }}>
              {/* Title */}
              <Typography level="h4">Sequence Contacts</Typography>

              {/* Actions */}
              <Box sx={{ display: 'flex', gap: 1 }}>
                {filtersApplied && selectedContacts.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}>
                    <Dropdown>
                      <MenuButton variant="solid" color="primary" endDecorator={<ExpandMoreIcon />}>
                        Sequences
                      </MenuButton>
                      <Menu placement="bottom-start">
                        {otherSequences.map((sequence, index) => (
                          <MenuItem onClick={() => handleSequenceClick(sequence)} key={index}>
                            {sequence.name}
                          </MenuItem>
                        ))}
                        <MenuItem onClick={() => navigate('/manage-sequence')}>
                          <IconButton variant="solid" color="primary" sx={{ width: '100%' }}>
                            Create
                          </IconButton>
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </Box>
                )}
                <IconButton
                  size="sm"
                  color="primary"
                  variant="solid"
                  onClick={handleRefreshContacts}
                  aria-label="Refresh Contacts">
                  <RefreshIcon />
                </IconButton>
              </Box>
            </Box>

            {/* Counts */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                gap: 1,
                flexWrap: 'wrap'
              }}>
              <Chip
                size="sm"
                color="primary"
                variant="soft"
                startDecorator={<Typography>Total:</Typography>}>
                {`${contacts.length}/${totalCount}`}
              </Chip>
              {selectedContacts.length > 0 && (
                <Chip
                  size="sm"
                  color="warning"
                  variant="soft"
                  startDecorator={<Typography>Selected:</Typography>}>
                  {selectedContacts.length}
                </Chip>
              )}
            </Box>

            {loading ? (
              <Skeleton variant="rectangular" height={400} />
            ) : (
              <Box sx={{ flex: 1, overflowX: 'auto' }}>
                <Table
                  hoverRow
                  variant="outlined"
                  borderAxis="bothBetween"
                  stickyHeader
                  sx={{
                    minWidth: '800px',
                    tableLayout: 'auto',
                    '& thead th': {
                      backgroundColor: 'background.level1',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1
                    },
                    '& th, & td': {
                      padding: '0.5rem',
                      height: '10px'
                    },
                    '& tbody tr:nth-of-type(odd)': {
                      backgroundColor: 'background.level1'
                    },
                    '& tbody tr:hover': {
                      backgroundColor: 'background.level2'
                    }
                  }}>
                  <thead>
                    <tr>
                      <th style={{ width: '50px' }}>
                        <Checkbox
                          indeterminate={
                            selectedContacts.length > 0 &&
                            selectedContacts.length < paginatedContacts.length
                          }
                          checked={
                            paginatedContacts.length > 0 &&
                            selectedContacts.length === paginatedContacts.length
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      </th>
                      {visibleTableFields.map((field) => (
                        <th
                          key={field}
                          onClick={() => handleSort(field)}
                          style={{ cursor: 'pointer' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                            {headersMap[field]}
                            {sortConfig?.key === field ? (
                              sortConfig.direction === 'asc' ? (
                                <ArrowUpward fontSize="small" />
                              ) : (
                                <ArrowDownward fontSize="small" />
                              )
                            ) : null}
                          </Box>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedContacts.length === 0 ? (
                      <tr>
                        <td colSpan={visibleTableFields.length + 2}>
                          <Typography level="body-md" sx={{ textAlign: 'center', p: 2 }}>
                            No contacts found
                          </Typography>
                        </td>
                      </tr>
                    ) : (
                      paginatedContacts.map((contact) => (
                        <tr
                          key={contact.ContactId}
                          style={{
                            backgroundColor: selectedContacts.some(
                              (c) => c.ContactId === contact.ContactId
                            )
                              ? 'rgba(0, 0, 0, 0.05)'
                              : 'inherit'
                          }}>
                          <td>
                            <Checkbox
                              checked={selectedContacts.some(
                                (c) => c.ContactId === contact.ContactId
                              )}
                              onChange={() => handleSelectContact(contact)}
                            />
                          </td>
                          {visibleTableFields.map((field) => (
                            <td
                              key={field}
                              style={{
                                ...(field === 'JobTitle' && { minWidth: '150px' })
                              }}>
                              {getDisplayValue(field, contact[field])}
                            </td>
                          ))}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 2,
                flexWrap: 'wrap',
                gap: 1
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography level="body-sm" sx={{ mr: 1 }}>
                  Rows per page:
                </Typography>
                <FormControl size="sm">
                  <Select
                    onChange={(_, newValue) => handleChangeRowsPerPage(Number(newValue))}
                    value={rowsPerPage}
                    sx={{ minWidth: '80px' }}>
                    <Option value={100}>100</Option>
                    <Option value={200}>200</Option>
                    <Option value={500}>500</Option>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography level="body-sm" sx={{ mr: 2 }}>
                  Page {page + 1} of {Math.ceil(contacts.length / rowsPerPage)}
                </Typography>
                <IconButton
                  size="sm"
                  onClick={() => handleChangePage('prev')}
                  disabled={page === 0}
                  aria-label="Previous Page">
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  size="sm"
                  onClick={() => handleChangePage('next')}
                  aria-label="Next Page">
                  <KeyboardArrowRight />
                </IconButton>
              </Box>
            </Box>
          </Sheet>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SequenceContacts;
