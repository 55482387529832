import { Box, Card, CssVarsProvider, Sheet, Typography } from '@mui/joy';
import SendGridLogo from '../assets/sendgrid.svg';
import { SenderEmails, SendgridAccount, AccountLimits } from '../components/sendgrid-integration';
import { IAccountLimits, ISenderEmails, ISignatures } from '../interfaces/common';
import { useEffect, useState } from 'react';
import { getSenderEmails } from '../services/api';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../utils/api';

const SendgridIntegrationPage: React.FC = () => {
  const [senderEmailDetails, setSenderEmailDetails] = useState<
    ISenderEmails & IAccountLimits & ISignatures
  >({
    email1: '',
    email2: '',
    daily_limit: 0,
    hourly_limit: 0,
    signature1: '',
    signature2: ''
  });

  useEffect(() => {
    const req = async () => {
      try {
        const response = await getSenderEmails();
        setSenderEmailDetails(response.data);
      } catch (error) {
        const errMsg = handleAxiosError(error);
        toast.error(`Error fetching sender emails: ${errMsg}`);
        console.warn('Error fetching sender emails', errMsg);
      }
    };
    req();
  }, []);

  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          margin: 'auto',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Card
            variant="soft"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img src={SendGridLogo} alt="send_grid_logo" />
          </Card>
          <Typography level="h4">integration</Typography>
        </Box>

        <Typography level="title-md" color="neutral">
          Connect with Sendgrid to more safely send high volumes of emails.
        </Typography>
        <SenderEmails senderEmails={senderEmailDetails} />
        <SendgridAccount />
        <AccountLimits accountLimits={senderEmailDetails} />
      </Sheet>
    </CssVarsProvider>
  );
};

export default SendgridIntegrationPage;
