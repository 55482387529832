export interface IUser {
  cognito_id: string;
  company: {
    name: string;
    role: string;
    sendgrid_api_key: string | null;
  };
  name: string;
  email: string;
  id: number;
}

export interface IContact {
  phone?: string;
  contactId?: string;
  jobTitle?: string;
  status?: string;
  linkedIn?: string;
  fileName?: string;
  industry?: string;
  lastEvent?: null;
  companyName?: string;
  email?: string;
  companySize?: string;
  userId?: number;
  currentStepId?: number;
  lastUpdated?: string;
  sequenceId?: string;
  firstName?: string;
  lastName?: string;
  linkedin?: string;
  companyId?: number;
}

export interface IApiContact {
  ContactId: string;
  FirstName: string;
  LastName: string;
  JobTitle: string;
  CompanyName: string;
  Email: string;
  Phone: string | null;
  Industry: string;
  LinkedIn: string | null;
  CompanySize: string | null;
  UserId: number;
  CompanyId: number;
  Status: string;
  LastEvent: string | null;
  CurrentStepId: number;
  LastUpdated: string;
  SequenceId: string;
  FileName: string;
}

export interface ISeqContacts {
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyName: string;
  email: string;
  phone: string;
  industry: string;
  stepNumber: number;
  linkedin: string;
  isFinished: boolean;
}

export interface ICounts {
  scheduled: number;
  delivered: number;
  open: number;
  reply: number;
  click: number;
  bounced: number;
  spam_blocked: number;
  opt_out: number;
}

export interface IStep {
  id: number;
  step_order: number;
  days_after_last_step: number;
  email_subject: string;
  email_body: string;
  status: string;
  last_event: null | string;
  last_updated: string;
  counts: ICounts;
}
export interface ISequence {
  id: number;
  name: string;
  status: string;
  last_event: null | string;
  last_updated: string;
  counts: ICounts;
  steps: IStep[];
}

export interface IMailbox {
  user_id: string;
  access_token: string;
  refresh_token: string;
  expiry_date: number;
  user_email: string;
}

export interface IStats {
  dynamoStats: {
    totalCount: number;
    records: Array<{
      UserId: number;
      Count: number;
      CompositeKey: string;
    }>;
  };
  sendGridStats: {
    blocks: number;
    bounce_drops: number;
    bounces: number;
    clicks: number;
    deferred: number;
    delivered: number;
    invalid_emails: number;
    opens: number;
    processed: number;
    requests: number;
    spam_report_drops: number;
    spam_reports: number;
    unique_clicks: number;
    unique_opens: number;
    unsubscribe_drops: number;
    unsubscribes: number;
  };
}

export interface ISenderEmails {
  email1: string;
  email2: string;
}
export interface ISignatures {
  signature1: string;
  signature2: string;
}

export interface IAccountLimits {
  daily_limit: number;
  hourly_limit: number;
}

export enum StatsType {
  GLOBAL = 'Global',
  WEEKLY = 'Last 7 Days',
  DAILY = 'Since Yesterday'
}

export type TCombinedStats = Record<StatsType, IStats | null>;

export enum ContactsType {
  ALL = 'All',
  SEQUENCES = 'Sequences'
}
