import { Box, Button, FormControl, FormLabel, Textarea } from '@mui/joy';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../../utils/api';
import { isValidEmail } from '../../utils/general';
import { updateContactStatusForSequence } from '../../services/api';

interface IRepliedEmails {
  sequenceId: number;
}

export default function RepliedEmails({ sequenceId }: IRepliedEmails) {
  const [loading, setLoading] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');

  const handleUpdateEmails = async () => {
    try {
      setLoading(true);
      const emailsArray = emails.split(',').map((email) => email.trim());
      if (!emailsArray.every((email) => isValidEmail(email))) {
        toast.error('Invalid emails');
        return;
      }
      await updateContactStatusForSequence({ emails: emailsArray, sequenceId });
      toast.success(`Emails updated successfully for sequence ${sequenceId}`);
    } catch (error) {
      const errMsg = handleAxiosError(error);
      toast.error(`Error updating replied emails: ${errMsg}`);
      console.error('Error updating replied emails:', errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" display="flex" flexDirection="column" gap={2} maxWidth={400} mt={2}>
      <FormControl>
        <FormLabel>Emails</FormLabel>
        <Textarea
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Enter emails separated by commas"
          sx={{ mt: 1 }}
          minRows={5}
        />
      </FormControl>

      <Button onClick={handleUpdateEmails} loading={loading} variant="solid" color="primary">
        Update Emails
      </Button>
    </Box>
  );
}
