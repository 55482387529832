import { Edit as EditIcon, Email as EmailIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, Drawer, IconButton, Input, Sheet, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ISequence } from '../../interfaces/common';
import { updateStep } from '../../services/api';
import { useAppDispatch } from '../../store';
import { updateSequenceStep } from '../../store/app';
import { handleAxiosError } from '../../utils/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

const statDisplayNames: { [key: string]: string } = {
  delivered: 'Delivered',
  click: 'Clicked',
  open: 'Open',
  bounce: 'Bounced',
  spam_blocked: 'Spam Blocked',
  scheduled: 'Scheduled',
  reply: 'Reply',
  opt_out: 'Opt Out'

  // interested: 'Interested',
};

interface IOverviewTab {
  sequence: ISequence;
  handleDelete: (stepId: number) => void;
  newStepAdded: boolean;
  setNewStepAdded: (newStepAdded: boolean) => void;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const getAvailablePlaceholders = (): string[] => {
  return [
    '{{firstName}}',
    '{{lastName}}',
    '{{companyName}}',
    '{{linkedin}}',
    '{{industry}}',
    '{{jobTitle}}',
    '{{email}}',
    '{{phone}}',
    '{{companySize}}'
  ];
};

type StepDetails = {
  stepId: number;
  daysAfterLastStep: number;
};

const OverviewTab: React.FC<IOverviewTab> = ({
  sequence,
  handleDelete,
  newStepAdded,
  setNewStepAdded
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [previewSubject, setPreviewSubject] = useState<string>('');
  const [previewBody, setPreviewBody] = useState<string>('');
  const [editingStep, setEditingStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [stepDetails, setStepDetails] = useState<StepDetails>({ stepId: 0, daysAfterLastStep: 0 });

  const lastStepId = sequence?.steps[sequence?.steps.length - 1]?.id;

  const dispatch = useAppDispatch();

  // Use useEffect to generate preview on initial render and whenever template or subject changes
  useEffect(() => {
    generatePreview(template, subject);
  }, [template, subject]);

  const openDrawer = (stepId: number) => {
    const stepToEdit = sequence.steps.find((step) => step.id === stepId);
    if (stepToEdit) {
      setTemplate(stepToEdit.email_body);
      setSubject(stepToEdit.email_subject);
    }
    setEditingStep(stepId);
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    if (lastStepId && newStepAdded) {
      openDrawer(lastStepId);
      setNewStepAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStepId, newStepAdded]);

  const closeDrawer = () => {
    setEditingStep(0);
    setIsDrawerOpen(false);
  };

  const handleTemplateChange = (value: string) => {
    setTemplate(value);
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const generatePreview = (bodyTemplate: string, subjectTemplate: string) => {
    // Placeholder mapping with example values
    const placeholders: { [key: string]: string } = {
      '{{firstName}}': 'John',
      '{{lastName}}': 'Doe',
      '{{companyName}}': 'Google',
      '{{linkedin}}': 'https://linkedin.com/in/johndoe',
      '{{industry}}': 'Technology',
      '{{jobTitle}}': 'Software Engineer',
      '{{email}}': 'john.doe@gmail.com',
      '{{phone}}': '+1 (123) 456-7890',
      '{{companySize}}': '500-1000'
    };

    let previewBody = bodyTemplate;
    let previewSubject = subjectTemplate;

    // Replace predefined placeholders in body and subject
    Object.entries(placeholders).forEach(([key, value]) => {
      const escapedKey = escapeRegExp(key);
      const regex = new RegExp(escapedKey, 'g');
      previewBody = previewBody.replace(regex, value);
      previewSubject = previewSubject.replace(regex, value);
    });

    // Remove any remaining placeholders
    const placeholderPattern = /{{.*?}}/g;
    previewBody = previewBody.replace(placeholderPattern, ''); // Replace remaining placeholders with an empty string
    previewSubject = previewSubject.replace(placeholderPattern, ''); // Replace remaining placeholders with an empty string

    // Set the preview state to display the updated values
    setPreviewBody(previewBody);
    setPreviewSubject(previewSubject);
  };

  const handleSaveTemplate = async () => {
    if (!subject.trim() || !template.trim() || template.trim() === '<p><br></p>') {
      toast.error('Please fill in all fields');
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        template: {
          email_subject: subject,
          email_body: template
        },
        sequence_id: sequence.id,
        step_id: editingStep
      };
      const response = await updateStep(payload);
      if (response.status === 200) {
        dispatch(updateSequenceStep(payload));
        toast.success('Template Updated');
        setIsLoading(false);
        closeDrawer();
      }
    } catch (err) {
      const errMsg = handleAxiosError(err);
      console.warn('Error updating step', errMsg);
      toast.error(`Error updating step: ${errMsg}`);
      setIsLoading(false);
    }
  };

  const handleUpdateStep = async (stepId: number, daysAfterLastStep: number) => {
    setIsStepLoading(true);
    try {
      const response = await updateStep({
        template: {
          days_after_last_step: daysAfterLastStep
        },
        sequence_id: sequence.id,
        step_id: stepId
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      dispatch(
        updateSequenceStep({
          sequence_id: sequence.id,
          step_id: stepId,
          template: {
            days_after_last_step: daysAfterLastStep
          }
        })
      );
      setStepDetails({ stepId: 0, daysAfterLastStep: 0 });
      setIsStepLoading(false);
    } catch (err) {
      setIsStepLoading(false);
      const errMsg = handleAxiosError(err);
      console.warn('Error updating step', errMsg);
      toast.error(`Error updating step: ${errMsg}`);
    }
  };

  return (
    <React.Fragment>
      {/* Sequence Counts */}
      <Sheet
        variant="soft"
        sx={{
          p: 2,
          borderRadius: 'md',
          bgcolor: 'background.level1',
          boxShadow: 'sm'
        }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(4, 1fr)',
              md: 'repeat(8, 1fr)'
            },
            gap: 2,
            alignItems: 'center'
          }}>
          {Object.entries(sequence.counts)
            .filter(([key]) => key !== 'processed')
            .map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 1
                }}>
                <Typography level="h4">{value}</Typography>
                <Typography level="body-md" color="neutral" textAlign="center">
                  {statDisplayNames[key] || key}
                </Typography>
              </Box>
            ))}
        </Box>
      </Sheet>

      {/* Steps */}
      <Typography level="h4" sx={{ mb: 2, mt: 4 }}>
        Steps
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {sequence?.steps?.map((step, index) => (
          <Sheet
            key={step.id}
            variant="soft"
            sx={{
              p: 3,
              borderRadius: 'md',
              bgcolor: 'background.level1',
              boxShadow: 'sm'
            }}>
            {/* Step Header */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <EmailIcon color="primary" />
                <Typography level="h4">Step {step.step_order}</Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  color="primary"
                  aria-label="edit step"
                  onClick={() => openDrawer(step.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="warning"
                  aria-label="delete step"
                  onClick={() => handleDelete(step.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            {/* Step Details */}
            <Box sx={{ mb: 2 }}>
              <Typography level="body-md">
                <strong>Email Subject:</strong> {step.email_subject}
              </Typography>
              <Typography level="body-md" sx={{ my: 1 }}>
                <strong>Email Body:</strong>
              </Typography>
              <ReactQuill
                theme="snow"
                className="quill-readonly"
                value={step.email_body}
                readOnly
              />
            </Box>

            {/* Additional Step Info */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'nowrap', alignItems: 'center' }}>
              <Typography level="body-md">
                <strong>Status:</strong> {step.status}
              </Typography>

              <Typography level="body-md" sx={{ display: 'flex', alignItems: 'center' }}>
                <strong>Days After Last Step:</strong>
                {index === 0 ? (
                  <span style={{ marginLeft: '0.2rem' }}>{step?.days_after_last_step}</span>
                ) : (
                  <>
                    <Input
                      slotProps={{ input: { maxLength: 3 } }}
                      placeholder="0"
                      sx={{ width: '4rem', ml: 1 }}
                      value={Math.max(
                        step.id === stepDetails.stepId
                          ? stepDetails.daysAfterLastStep
                          : step.days_after_last_step,
                        1
                      )}
                      type="number"
                      readOnly={false}
                      onChange={(e) =>
                        setStepDetails((prev) => ({
                          ...prev,
                          stepId: step.id,
                          daysAfterLastStep: Math.max(Number(e.target.value), 1)
                        }))
                      }
                    />
                    <Button
                      disabled={
                        step.id === stepDetails.stepId
                          ? step.days_after_last_step === stepDetails.daysAfterLastStep
                          : true
                      }
                      variant={step.id === stepDetails.stepId ? 'solid' : 'outlined'}
                      color="primary"
                      onClick={() => handleUpdateStep(step.id, stepDetails.daysAfterLastStep)}
                      loading={isStepLoading && step.id === stepDetails.stepId}
                      sx={{ ml: 1 }}>
                      UPDATE
                    </Button>
                  </>
                )}
              </Typography>

              <Typography level="body-md">
                <strong>Last Updated:</strong> {new Date(step.last_updated).toLocaleDateString()}
              </Typography>

              {step.last_event && (
                <Typography level="body-md">
                  <strong>Last Event:</strong> {step.last_event}
                </Typography>
              )}
            </Box>

            {/* Step Counts */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' },
                gap: 2
              }}>
              {Object.entries(step?.counts || {})
                .filter(([key]) => key !== 'processed')
                .map(([key, value]) => (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    <Typography level="h4">{value}</Typography>
                    <Typography level="body-sm" color="neutral" textAlign="center">
                      {statDisplayNames[key] || key}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Sheet>
        ))}
      </Box>

      {/* Drawer for Editing Step */}
      <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor="right" size="lg">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row'
            },
            overflow: 'hidden'
          }}>
          {/* Template Editing Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: 3,
              overflowY: 'auto',
              borderRight: {
                md: '1px solid'
              },
              borderColor: 'divider'
            }}>
            <Typography level="h4" sx={{ mb: 2 }}>
              Edit Email Template
            </Typography>
            <Input
              placeholder="Email Subject"
              value={subject}
              onChange={handleSubjectChange}
              sx={{ mb: 2 }}
            />

            <Box>
              <ReactQuill
                className="quill-editor"
                theme="snow"
                value={template}
                onChange={handleTemplateChange}
              />
            </Box>

            {/* Available Variables Section */}
            <Box
              sx={{ mt: 2, p: 2, border: '1px solid', borderRadius: 'md', borderColor: 'divider' }}>
              <Typography level="h4" sx={{ mb: 1 }}>
                Available Variables
              </Typography>
              <Typography level="body-sm" color="neutral">
                You can use the following placeholders inside the email template:
              </Typography>
              <Box sx={{ mt: 1 }}>
                {getAvailablePlaceholders().map((placeholder, index) => (
                  <Typography key={index} level="body-sm" color="primary">
                    {placeholder}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Preview Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: 3,
              overflowY: 'auto'
            }}>
            <Typography level="h4" sx={{ mb: 2 }}>
              Preview
            </Typography>
            <Sheet
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                bgcolor: 'background.paper',
                flexGrow: 1,
                overflowY: 'auto'
              }}>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                <strong>To:</strong> Example Contact &lt;example@google.com&gt;
              </Typography>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                <strong>Subject:</strong> {previewSubject}
              </Typography>
              <Typography level="body-sm" sx={{ mb: 1 }}>
                <strong>Body:</strong>
              </Typography>
              <ReactQuill theme="snow" className="quill-readonly" value={previewBody} readOnly />
            </Sheet>
          </Box>
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            mt: 2,
            px: 3,
            pb: 2,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}>
          <Button variant="plain" color="neutral" onClick={closeDrawer}>
            Cancel
          </Button>
          <Button variant="solid" loading={isLoading} color="primary" onClick={handleSaveTemplate}>
            Save
          </Button>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default OverviewTab;
