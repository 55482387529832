import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Sheet,
  Skeleton,
  Typography
} from '@mui/joy';
import React from 'react';
import { IApiContact, IContact, ISequence } from '../../interfaces/common';
import { useAppSelector } from '../../store';

interface FilterPanelProps {
  filters: Record<string, string>;
  inputValues: Record<string, string>;
  handleInputChange: (key: string, value: string) => void;
  handleAddFilter: (key: string) => void;
  handleDeleteFilter: (key: string) => void;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  handleSelectSequence?: (sequence: ISequence) => void;
  handleSelectStatus?: (status: string) => void;
  isApplyDisabled: boolean;
  loading: boolean;
  tableFields: (keyof IContact)[] | (keyof IApiContact)[];
  headersMap: { [key in keyof IContact]: string } | { [key in keyof IApiContact]: string };
  filtersApplied: boolean;
  componentTypes?: Record<string, 'Date' | 'List' | 'Text' | 'Number'>;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  filters,
  inputValues,
  handleInputChange,
  handleAddFilter,
  handleDeleteFilter,
  handleApplyFilters,
  handleResetFilters,
  handleSelectSequence,
  handleSelectStatus,
  isApplyDisabled,
  loading,
  tableFields,
  headersMap,
  filtersApplied,
  componentTypes
}) => {
  const sequences = useAppSelector((state) => state.app.sequences);

  const getChipText = (field: string) => {
    if (field === 'sequenceId') {
      return sequences.find((sequence) => sequence.id === Number(filters[field]))?.name;
    }
    return filters[field];
  };

  return (
    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'md', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
        <Typography level="h4">Filters</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {filtersApplied && (
            <Button onClick={handleResetFilters} variant="soft" color="neutral" size="sm">
              Reset
            </Button>
          )}
          <Button
            onClick={handleApplyFilters}
            variant="solid"
            color="primary"
            size="sm"
            disabled={isApplyDisabled}>
            Apply
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        tableFields.map((field) => {
          const isDateComponent = componentTypes?.[field] === 'Date';
          const isListComponent = componentTypes?.[field] === 'List';
          const isNumberComponent = componentTypes?.[field] === 'Number';

          return (
            <FormControl key={field} sx={{ mb: 2 }}>
              <FormLabel>{headersMap[field as keyof typeof headersMap]}</FormLabel>
              {!isListComponent && (
                <Input
                  type={isDateComponent ? 'date' : isNumberComponent ? 'number' : 'text'}
                  name={field}
                  value={inputValues[field] || ''}
                  onChange={(e) => {
                    handleInputChange(field, e.target.value);
                  }}
                  disabled={filters[field]?.length > 0}
                  endDecorator={
                    <Button
                      variant="soft"
                      onClick={() => handleAddFilter(field)}
                      size="sm"
                      disabled={!inputValues[field]?.trim()}>
                      <Add />
                    </Button>
                  }
                />
              )}
              {isListComponent && field.toLowerCase() === 'sequenceid'.toLowerCase() && (
                <Select
                  value={
                    sequences.find((sequence) => sequence.id === Number(inputValues[field]))
                      ?.name || ''
                  }
                  onChange={(_, value) => {
                    const sequence = sequences.find((sequence) => sequence.name === value);
                    sequence && handleSelectSequence?.(sequence);
                  }}
                  disabled={filters[field]?.length > 0 || sequences.length === 0}>
                  {sequences.map((sequence) => (
                    <Option key={sequence.id} value={sequence.name}>
                      {sequence.name}
                    </Option>
                  ))}
                </Select>
              )}
              {isListComponent && field.toLowerCase() === 'status'.toLowerCase() && (
                <Select
                  value={inputValues[field] || ''}
                  onChange={(_, value) => {
                    value && handleInputChange(field, value);
                    value && handleSelectStatus?.(value);
                  }}
                  disabled={filters[field]?.length > 0}>
                  <Option value="completed">Completed</Option>
                  <Option value="in_progress">In Progress</Option>
                  <Option value="scheduled">Scheduled</Option>
                </Select>
              )}
              <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                {filters[field] && (
                  <Chip
                    size="sm"
                    variant="soft"
                    color="primary"
                    endDecorator={<ChipDelete onDelete={() => handleDeleteFilter(field)} />}
                    sx={{ mr: 0.5, mb: 0.5 }}>
                    {getChipText(field)}
                  </Chip>
                )}
              </Box>
            </FormControl>
          );
        })
      )}
    </Sheet>
  );
};

export default FilterPanel;
