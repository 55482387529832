import { Button, Card, FormControl, FormHelperText, Grid, Input, Typography } from '@mui/joy';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  AccountLimitsInitialValues,
  AccountLimitsSchema,
  TAccountLimitsSchema
} from '../../constants/yup';
import { IAccountLimits } from '../../interfaces/common';
import { TEmailLimitsPayload } from '../../interfaces/api';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../../utils/api';
import { updateEmailLimits } from '../../services/api';

interface IProps {
  accountLimits: IAccountLimits;
}

export function AccountLimits({ accountLimits }: IProps) {
  const handleUpdateAccountLimits = async (
    values: TAccountLimitsSchema,
    { setSubmitting, setErrors }: FormikHelpers<TAccountLimitsSchema>
  ) => {
    try {
      setSubmitting(true);
      const { daily_limit, hourly_limit } = values;
      if (daily_limit > 0 && hourly_limit > 0) {
        const payload: TEmailLimitsPayload = {
          mailBoxId: 'default',
          daily: Number(daily_limit || 0),
          hourly: Number(hourly_limit || 0)
        };
        const response = await updateEmailLimits(payload);
        if (response.status === 200) {
          toast.success('Email limits updated successfully');
        }
      }
    } catch (error) {
      const errMsg = handleAxiosError(error);
      console.warn('Error adding sender emails', errMsg);
      toast.error(`Error adding sender emails: ${errMsg}`);
      setErrors({ daily_limit: errMsg, hourly_limit: errMsg });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card variant="outlined">
      <Typography level="title-md">Account Limits</Typography>
      <Formik
        initialValues={AccountLimitsInitialValues}
        validationSchema={AccountLimitsSchema}
        onSubmit={handleUpdateAccountLimits}>
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid xs={6}>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                  Daily limit
                </Typography>
                <FormControl>
                  <Field
                    as={Input}
                    name="daily_limit"
                    type="number"
                    placeholder={`${accountLimits?.daily_limit || 0}`}
                    value={values.daily_limit || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = Number(e.target.value) || 0;
                      setFieldValue('daily_limit', value);
                    }}
                    error={touched.daily_limit && !!errors.daily_limit}
                  />
                  {touched.daily_limit && errors.daily_limit && (
                    <FormHelperText>{errors.daily_limit}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                  Hourly limit
                </Typography>
                <FormControl>
                  <Field
                    as={Input}
                    name="hourly_limit"
                    type="number"
                    placeholder={`${accountLimits?.hourly_limit || 0}`}
                    value={values.hourly_limit || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = Number(e.target.value) || 0;
                      setFieldValue('hourly_limit', value);
                    }}
                    error={touched.hourly_limit && !!errors.hourly_limit}
                  />
                  {touched.hourly_limit && errors.hourly_limit && (
                    <FormHelperText>{errors.hourly_limit}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid>
                <Button type="submit" loading={isSubmitting} sx={{ alignSelf: 'flex-start' }}>
                  Update Account Limits
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
